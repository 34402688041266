<template>
	<el-row v-if="Object.keys(cashDetail).length>0">
		<el-col :span="4">合伙人姓名：{{cashDetail.realName}}</el-col>
		<el-col :span="4">合伙人昵称：{{cashDetail.nickName}}</el-col>
		<el-col :span="4">合伙人电话：{{cashDetail.userMobile}}</el-col>
	</el-row>
</template>

<script>
	export default{
		props: {
			selectdata: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				cashDetail: {}
			};
		},
		created() {
			this.getCashDetail();
		},
		methods: {
			async getCashDetail() {
				const res = await this.$axios('post', `/sys/cashBack/detail`, {cashBackId:this.selectdata.cashBackId,toUserId:this.selectdata.toUserId}, true);
				this.cashDetail = res;
			}
		}
	}
</script>

<style>
</style>
